/* General purpose */
.vh-center {
  margin: 0;
  position: absolute;
  top: 40vh;
  width: 100%;
  text-align: center;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.all-center {
  justify-content: center;
  align-items: center;

}

.content-page {
  height: 100%;
  margin-bottom: 17%;
}


/* Header & Navigation */

.navigation {
  opacity: 0%;
  backgound-color: none;
}

header a {
  color: white;
}

.custom-link {
  color: white;
}

.logo-text:hover {
  color: orange;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: orange;
}


.logo-text {
  text-decoration: none;
}

.logo-text:hover {
  text-decoration:none;
}

.navbar-brand {
  margin-left: 1em;
}

/* Footer */
.footer {
  position: sticky;
  background-color: #343a40;
  color: white;
  height: 100%;
  padding-bottom: 10vh;
}

.footer a {
  color: orange;
}

.footer a:hover {
  color: white;
}

.footer h3 {
  font-size: 14pt;
  padding-top: 1em;
}

.footer ul {
  display: inline-block;
  padding-left: 0px;
  text-decoration: none;
}

.footer li {
  text-decoration: none;
  font-size: 12pt;
  list-style-type: none;
  margin-right: .5rem;
  padding-left: 0;
  text-align: left;
  display: inline-flex;
}

.footer p {
  font-size: 12pt;
}

/* Landing Page */

.landing {
  /* The image used */
  background-image: url(../images/lisbon_view.jpg);

  /* Full height */
  height: 100vh;
  margin: 0vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Style */
}

.landing h1 {
  color: white;
}

.landing p {
  color: black;
}

.site-map ul {
  text-decoration: none;
  padding-left: 0;
  display: inline-block;
}

.site-map li {
  display: inline-block;
  margin-right: .5rem;
}

.code-row-2-margin-top {
  margin-top: 2rem;
}