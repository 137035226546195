.bands-list {
    padding: 1em;
}

.bands-container {
    padding-top: 5vh;
}

.bands-container ul li {
    padding-bottom: 1em;
}

.goals-container {
    padding-top: 1em;
}

.goals-container ul {
    padding: 1em;
}

.goals-container ul li {
    padding-bottom: 1em;
}

.music-container {
    padding-top: 1em;
}

